import React from 'react'
import Breadcrumbs from '../components/breadcrumbs'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

class PrivacyPage extends React.Component {
  render() {
    const breadcrumb = [{ title: 'Privacy e Cookie' }]
    const data = this.props.data.contentfulContenutoGenerico
    return (
      <Layout location={this.props.location}>
        <div className="container">
          <Helmet title="Privacy e Cookie" />
          <Breadcrumbs breadcrumb={breadcrumb} />
          <div className="row">
            <div className="col-md-12" style={{ marginBottom: '48px' }}>
              <h1>Privacy e Cookie</h1>
              {!!data && (
                <div
                  className="bodytext"
                  dangerouslySetInnerHTML={{
                    __html: data.testo.childMarkdownRemark.html,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default PrivacyPage

export const query = graphql`
  query PrivacyQuery {
    contentfulContenutoGenerico(
      contentful_id: { eq: "7XyID8PNo4iNQiSeeM1yU" }
    ) {
      testo {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
